<template>
    <ion-page>
        <template v-if="isAuthorized">
            <ion-content :fullscreen="true">
                <ion-tabs>
                    <ion-header class="header-desktop-maps">
                        <ion-toolbar>
                            <ion-buttons slot="primary">
                                <ion-button @click="logout">Выход</ion-button>
                            </ion-buttons>
                            <ion-title>Панель управления</ion-title>
                        </ion-toolbar>
                    </ion-header>
                    
                    <ion-router-outlet></ion-router-outlet>
                    <ion-tab-bar>
                        <ion-tab-button
                            href="/admin/points"
                            tab="points"
                        >
                            <ion-icon name="prism"/>
                            <ion-label>Достопримечательности</ion-label>
                        </ion-tab-button>
                        <ion-tab-button
                            href="/admin/tracks"
                            tab="tracks"
                        >
                            <ion-icon name="analytics"/>
                            <ion-label>Маршруты</ion-label>
                        </ion-tab-button>
                        <ion-tab-button
                            href="/admin/events"
                            tab="events"
                        >
                            <ion-icon name="calendar"/>
                            <ion-label>События</ion-label>
                        </ion-tab-button>
                    </ion-tab-bar>
                </ion-tabs>
            </ion-content>
        </template>
        
        <template v-else>
            <ion-router-outlet></ion-router-outlet>
        </template>
    </ion-page>
</template>

<script>
import {
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    alertController,
} from '@ionic/vue';
import {
    ellipsisHorizontal,
    ellipsisVertical,
    helpCircle,
    personCircle,
    search,
    star,
} from 'ionicons/icons';
import { StorageKeys } from '../scripts/storage_keys';

export default {
    name:       'MainLayoutAdmin',
    components: {
        IonButton,
        IonButtons,
        IonTitle,
        IonToolbar,
    },
    
    inject: [ 'isAuthorized' ],
    
    setup() {
        return {
            ellipsisHorizontal,
            ellipsisVertical,
            helpCircle,
            personCircle,
            search,
            star,
        };
    },
    
    methods: {
        async logout() {
            const alert = await alertController
                .create({
                    cssClass: 'add-point-confirm-close',
                    header:   'Подтвердите действие',
                    message:  'Вы уверены, что хотите выйти из панели администратора?',
                    buttons:  [
                        {
                            text:     'Отмена',
                            role:     'cancel',
                            cssClass: 'secondary',
                            handler:  blah => {
                                console.log('Confirm Cancel:', blah);
                            },
                        },
                        {
                            text:    'Подтвердить',
                            handler: () => {
                                localStorage.removeItem(StorageKeys.USER_ROLE);
                                localStorage.removeItem(StorageKeys.TOKEN_DATA);
                                location.reload();
                            },
                        },
                    ],
                });
            return alert.present();
        },
    },
};
</script>

<style scoped>

</style>
